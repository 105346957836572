export default [
  {
    key: 'select', tdClass: 'text-center', thClass: 'col-nowrap',
  },
  {
    key: 'consultadoEm', label: 'Consultado Em', thClass: 'col-nowrap', sortable: true,
  },
  {
    key: 'situacao', label: 'Situação', type: 'custom', thClass: 'col-nowrap', sortable: true,
  }]
